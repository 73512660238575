<template>
  <Card class="flex">
    <h2
      class="header-title"
      @click="navigateHome"
    >
      Rasmus Svanberg
    </h2>

    <div class="flex-fill" />

    <nav>
      <NavigationItemComponent
        :route="ROUTE_HOME"
        :title="'Home'"
      />
      <NavigationItemComponent
        :route="ROUTE_BLOG"
        :title="'Blog'"
      />
      <NavigationItemComponent
        :route="ROUTE_PROJECTS"
        :title="'Projects'"
      />
      <NavigationItemComponent
        :route="ROUTE_CONTACT"
        :title="'Contact'"
      />
    </nav>
    <font-awesome-icon
      class="search-icon"
      :icon="['fas', 'magnifying-glass']"
    />
    <!-- <input v-model="text" placeholder="Search" class="search-form"/> -->
  </Card>
</template>

<script>

import {
  ROUTE_HOME, ROUTE_BLOG, ROUTE_CONTACT, ROUTE_PROJECTS,
} from '@/router/';

import Card from '@/components/cards/card.vue';
import NavigationItemComponent from './components/navigation.item.component.vue';

export default {
  name: 'NavigationBar',
  data() {
    return {
      ROUTE_HOME,
      ROUTE_BLOG,
      ROUTE_CONTACT,
      ROUTE_PROJECTS,
    };
  },
  components: {
    Card,
    NavigationItemComponent,
  },
  methods: {
    navigateHome() {
      this.$router.push(ROUTE_HOME);
    },
  },
};

</script>

<style lang="scss" scoped>

  .header-title {
    margin-left: 20%;

    &:hover {
      cursor: pointer;
    }
  }

  .search-icon {
    color: var(--primary-color);
    height: 24px;
    width: 24px;
    margin: 25px 20% 25px 0;

    &:hover {
      cursor: pointer;
      color: var(--primary-color-light);
    }
  }

  .flex-fill {
    flex-grow: 1;
  }

  nav {
    display: inline-flex;
    padding: 30px;
  }

</style>
