<template>
  <div>
    <Header>
      <h2>Contact Page</h2>
    </Header>
  </div>
</template>

<script>

import Header from '@/components/headers/header.vue';

export default {
  name: 'ContactView',
  components: {
    Header,
  },
};

</script>

<style lang="scss" scoped>

</style>
