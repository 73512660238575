<template>
  <div>
    404: page not found
  </div>
</template>

<script>

export default {
  name: 'NotFoundView',
};

</script>

<style lang="scss" scoped>

</style>
